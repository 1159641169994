.description-buttons {
  display: flex;
  flex-direction: row;
  padding-top: 10px;
  padding-bottom: 15px;
}

.description-buttons .ant-btn {
  border-radius: 0px;
}

.description-info-content {
  padding-bottom: 10px;
  margin: 0px;
  height: 480px;
  overflow-y: auto;
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
}

.description-table {
  background-color: rgb(52, 48, 100);
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}
