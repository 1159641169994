.problem-list-container {
}

.problem-list-table {
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
  overflow: hidden;
  background-color: #202940;
}

.problem-list-table .ant-table-thead > tr > th {
  font-weight: bold;
}

.problem-list-table .ant-table-thead > tr > th::before {
  background-color: hsla(0, 0%, 71%, 0.1) !important;
}

.problem-list-table .ant-table-tbody > tr:hover > td {
}

.problem-list-table .ant-table-row {
  cursor: pointer;
  transition: all 0.3s;
}

.ant-btn-primary {
  box-shadow: none;
}
