.new-problem-container {
  padding: 20px;
  padding-top: 10px;
}

.name-input {
  border-radius: 0px;
  margin-bottom: 20px;
  color: black;
}
.time-memory-limit {
  margin-top: 70px;
  display: flex;
  flex-direction: row;
  gap: 40px;
  margin-bottom: 10px;

  align-items: center;
}
.time-memory-input {
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-bottom: 20px;
  justify-content: center;
}
.problem-details {
  margin-top: 10px;
}
.problem-details label {
  /* Your styles here */
  font-size: 14px;
  margin-bottom: 10px;
  display: block;
  color: #ffffff;
}
.save-button-problem-editor {
  margin-left: auto;
}

.time-memory-container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  gap: 20px;
}
