.problem-container {
  display: flex;
  flex-direction: row;
  height: 840px;
  width: 100%;
}

.left-problem-container {
  padding: 20px;
  padding-top: 6px;
}
.left-problem-container .ant-tabs-nav {
  margin: 0px;
  padding-left: 11px;
}

.left-problem-container .ant-tabs-nav::before {
  width: 0%;
}
.right-problem-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.tabs-header {
  display: flex;
  flex-direction: row;
}

.problem-tab-item {
  text-decoration: none;
  border: 0px;
  padding: 4px 8px;
  border-radius: 5px;
  opacity: 0.4;
  box-sizing: border-box;
  font-weight: 400;
}
.problem-tab-item-active {
  text-decoration: none;
  border: 0px;
  padding: 4px 8px;
  border-radius: 5px;
  box-sizing: border-box;
  font-weight: 600;
}
.problem-description {
  display: flex;
  height: 754px;
  overflow-y: auto;
  overflow-x: auto;
  padding: 10px;
  padding-top: 0px;
  width: 700px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;
}
.problem-submission {
  height: 100%;

  padding: 10px;
  padding-top: 0px;
  width: 700px;
}

.description-content {
  white-space: pre-wrap; /* CSS3 */
  white-space: -moz-pre-wrap; /* Firefox */
  white-space: -pre-wrap; /* Opera <7 */
  white-space: -o-pre-wrap; /* Opera 7 */
  word-wrap: break-word; /* IE */
  color: #d9d9d9;
}

/* If you want to preserve some formatting for code blocks within the description */
.description-content pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  overflow-x: auto;
}

.problem-editor {
  flex-grow: 1;
  overflow-y: auto;
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.problem-header {
  padding: 10px;
  padding-left: 0px;
  max-width: 600px;
}

.problem-header .ant-btn {
  border-radius: 0px;
}
.submission-expand {
  font-family: monospace;
  background-color: #1e1e1e;
  color: #d4d4d4;
  padding: 10px;
  border-radius: 5px;
  white-space: pre-wrap;
  overflow-x: auto;

  overflow-y: auto; /* Allow vertical scrolling */
}

.problem-submission .anticon-right,
.problem-submission .anticon-left {
  color: white;
}

.problem-submission .ant-pagination-item {
  color: white;
}

.submission-table {
  height: 748px;
  overflow-y: auto;
}
.submission-table .ant-table-pagination {
  color: red !important;
}

.fail {
  color: #f14c4c;
}

.pass {
  color: #23d18b;
}

.indent {
  color: #569cd6;
}
.terminal-modal .ant-modal-content {
  background-color: #000;
  color: #00ff00;
  border-radius: 5px;
}

.terminal-modal .ant-modal-header {
  background-color: #333;
  border-bottom: 1px solid #00ff00;
  border-radius: 5px 5px 0 0;
}

.terminal-modal .ant-modal-title {
  color: #00ff00;
}

.terminal-modal .ant-modal-close {
  color: #00ff00;
}

.terminal-modal .ant-modal-footer {
  border-top: 1px solid #00ff00;
  background-color: #000;
}

.terminal-modal .ant-btn {
  background-color: #333;
  border-color: #00ff00;
  color: #00ff00;
}

.terminal-modal .ant-btn:hover {
  background-color: #00ff00;
  color: #000;
}

.terminal-content {
  background-color: #000;
  color: #00ff00;
  padding: 20px;
  margin: 0;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: "Courier New", Courier, monospace;
}

.code-editor-modal .ant-modal-content {
  background-color: #1e1e1e;
  border-radius: 4px;
}

.code-editor-modal .ant-modal-header {
  background-color: #313131;

  border-radius: 4px 4px 0 0;
  padding: 15px;
  padding-bottom: 5px;
  padding-top: 5px;
  width: fit-content;
}

.code-editor-modal .ant-modal-title {
  color: #cccccc;
}

.code-editor-modal .ant-modal-close {
  color: #cccccc;
}

.code-editor-modal .ant-modal-body {
  padding-top: 10px;
  padding-bottom: 10px;
}

.code-editor-modal pre {
  margin: 0 !important;
}
@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.expanded-row-animation {
  animation: slideDown 0.3s ease-out;
  background-color: #f8f8f8;
  border-radius: 4px;

  margin-top: 8px;
  border: 1px solid #e8e8e8;
  transition: all 0.3s ease;
  width: 650px;
}
.expanded-row-animation pre {
}
.expanded-row-animation:hover {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.result-content {
  font-family: "Courier New", Courier, monospace;
  white-space: pre-wrap;
  word-break: break-all;
}
