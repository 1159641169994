.test-page-container {
  display: flex;
  flex-direction: row;
  gap: 20px;
  padding-left: 24px;
  padding-right: 0px;
  padding-bottom: 0;
  padding-top: 0px;
  width: 100%;
  flex-basis: 1 1;
  max-height: 100%;
}
.test-page-container .ant-tabs-nav {
  margin: 0px;
}
.test-page-container .ant-tabs-nav::before {
  width: 0%;
}

.code-editor {
  border-radius: 4px;
  border-top: 0px;
  box-shadow:
    0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.2),
    0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

.tabs-extra-demo-button {
  margin-right: 25px;
}

.ant-row-rtl .tabs-extra-demo-button {
  margin-right: 25px;
  margin-left: 16px;
}
.tab-left {
  min-width: 700px;
}
.tab-right {
  width: 100%;
  min-width: 860px;
  display: flex;
}

/* Add this to your Test.css file or create a new CSS file and import it */
.tab-right .test-reset-button {
  margin-right: 8px; /* Space between buttons */
  transition: all 0.3s ease; /* Smooth transition for hover effects */
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.tab-right .test-reset-button:hover {
}
.tab-right .ant-tabs-ink-bar {
}

.tab-right .test-reset-button:active {
}

/* Style for the icon */
.tab-right .test-reset-button .anticon {
}
.language-button {
  border-radius: 0px;
  border: none;
}
.code-editor {
  border-radius: 4px;
}
.tab-right .ant-tabs-nav {
}
.tab-right .ant-tabs-nav::before {
}

.tab-right .ant-tabs-tab {
}

.tab-right .ant-tabs-tab:hover {
}

.tab-right .ant-tabs-tab.ant-tabs-tab-active {
}

/* Style for the language dropdown and save button */
.tab-right .ant-tabs-extra-content {
  padding: 0 10px;
}

.tab-right .language-button,
.tab-right .save-button {
  margin-left: 10px;
  margin-right: 10px;
  border-radius: 0px;
}

.tab-right .language-button:hover,
.tab-right .save-button:hover {
}

.tab-right .ant-tabs-tab-btn {
}
